import * as React from 'react'
import { Modal, Input, Button } from 'antd'
import Group from './../group/group'
import { GroupType } from './../../utils/index'
export interface IGroupMultiSelProps {
  title: string
  onChange: (e: any) => void
  type: GroupType
}
export interface IGroupMultiSelStates {
  visible: boolean
  selGroups: any
}

export default class GroupMultiSel extends React.Component<
  IGroupMultiSelProps,
  IGroupMultiSelStates
> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.showEditDialog = this.showEditDialog.bind(this)
    this.state = {
      visible: false,
      selGroups: [],
    }
  }

  showEditDialog = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e: any) => {
    this.setState({
      visible: false,
    })
    this.props.onChange(this.state.selGroups)
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }
  onCheck = (e: any) => {
    this.setState({
      selGroups: e,
    })
  }
  public render() {
    return (
      <span>
        <Modal
          width="30%"
          title={this.props.title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Group type={this.props.type} multi={true} onCheck={this.onCheck} />
        </Modal>
        <div className="flex-h mt-m">
          <Button type="primary" onClick={this.showEditDialog}>
            选择分类
          </Button>
        </div>
      </span>
    )
  }
}
