import * as React from 'react'
import {
  Table,
  Form,
  Button,
  Pagination,
  Tag,
  Upload,
  message,
  Popconfirm,
  Select,
  Input,
} from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import QuesAdd from './QuesAdd'
import { GroupType, QuesType, quesTypeArr } from './../../utils/index'
import { QUES_EXPORT } from './../../service/config'
import ModelButton from './../widget/ModelButton'
import GroupSel from '../widget/GroupSel'
import { quesList, quesDel, quesImp } from './../../service/ques'
import { moveToGroup, download } from './../../service/index'
import { FormComponentProps } from 'antd/lib/form'
import DocUploader from './../widget/DocUploader'
import { PERM, hasPermits } from './../../utils/permits'
import QuesBlock from '../widget/QuesBlock'

const { Column } = Table
const { Option } = Select

interface IQuesProps extends FormComponentProps {}
interface IQuesStates {
  dataSource?: any
  groupId: any
  total: number
  page: number
  pageSize: number
  checkedList: any
  uploadDialogVisible: boolean
  loading: boolean
}

class Ques extends React.Component<IQuesProps, IQuesStates> {
  constructor(props: any) {
    super(props)
    this.onChangeGroup = this.onChangeGroup.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.state = {
      uploadDialogVisible: false,
      dataSource: [],
      groupId: '',
      total: 0,
      page: 1,
      pageSize: 10,
      checkedList: [],
      loading: false,
    }
  }
  componentDidMount() {
    this.getQuesList()
  }

  handleSearch = (e: any) => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getQuesList()
    }, 0)
  }

  getQuesList = async () => {
    this.setState({
      loading: true,
    })
    const values = this.props.form!.getFieldsValue()
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      groupId: this.state.groupId,
      ...values,
    }
    const res = await quesList(params)
    setTimeout(() => {
      this.setState({
        dataSource: res.data,
        total: res.total,
        checkedList: [],
        loading: false,
      })
    }, 0)
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getQuesList()
    }, 0)
  }

  showEditDialog = () => {
    this.setState({})
  }

  handleOk = (e: any) => {
    this.setState({
      uploadDialogVisible: false,
    })
    this.getQuesList()
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      uploadDialogVisible: false,
    })
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]
    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getQuesList()
    }, 0)
  }
  onChangeGroup = async (group: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请选择题目')
      return
    }
    const params = {
      groupId: group ? group.groupId : '',
      serialIds: this.state.checkedList.join(','),
    }
    const res = await moveToGroup(params)
    if (res.success) {
      message.success('分组移动成功')
      setTimeout(() => {
        this.setState({ page: 1 })
        this.getQuesList()
      }, 0)
    } else {
      message.error('分组移动失败')
    }
  }
  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择题目')
      return
    }
    const res = await quesDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getQuesList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  handleUploadFileChange = async (e: any) => {
    const res = await quesImp(e)
    if (res.success) {
      message.success('导入成功')
      this.getQuesList()
    } else {
      message.error(res.msg)
      this.getQuesList()
    }
  }

  handleQuesExport = async (e: any) => {
    const values = this.props.form!.getFieldsValue()

    const params = {
      subjectType: values.subjectType || '',
      title: values.title || '',
      groupId: this.state.groupId,
      subjectIds: this.state.checkedList.join(','),
    }
    await download(QUES_EXPORT, '考题目', params)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any) => {
        this.setState({
          checkedList: selectedRowKeys,
        })
      },
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="题库管理" second="题目列表" />
        <Main
          leftTitle="题目分类列表"
          contentTitle="所有题目"
          leftContent={<Group type={GroupType.QUES} onSelect={this.onGroupSelect} />}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                <Form.Item>
                  {hasPermits(PERM.ques_func_add) && (
                    <QuesAdd icon="plus" buttonTitle="新增题目" handleOk={this.handleOk} />
                  )}
                  {hasPermits(PERM.ques_func_imp) && (
                    <DocUploader
                      buttonTitle="导入题目"
                      supportTypes="Excel"
                      handleUploadFileChange={this.handleUploadFileChange}
                    />
                  )}
                  {hasPermits(PERM.ques_func_exp) && (
                    <Button icon="export" type="primary" ghost onClick={this.handleQuesExport}>
                      导出所选题目({this.state.checkedList.length})
                    </Button>
                  )}
                </Form.Item>
                {hasPermits(PERM.ques_func_groupmod) && (
                  <Form.Item>
                    <GroupSel
                      title="移动到分类"
                      buttonTitle="移动到分类"
                      type={GroupType.QUES}
                      hideInput={true}
                      onChange={this.onChangeGroup}
                    />
                  </Form.Item>
                )}
                {hasPermits(PERM.ques_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除题目吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除题目
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.ques_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="标题">
                    {getFieldDecorator('title')(<Input placeholder="标题" allowClear />)}
                  </Form.Item>
                  <Form.Item label="题型">
                    {getFieldDecorator('subjectType')(
                      <Select style={{ width: 120 }} placeholder="题型" allowClear>
                        {quesTypeArr.map((ques: any) => {
                          return (
                            <Option key={ques.key} value={ques.key}>
                              {ques.name}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                current={this.state.page}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                dataSource={this.state.dataSource}
                expandedRowRender={(item) => <QuesBlock row={item} />}
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="subjectId"
                pagination={{
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  current: this.state.page,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
              >
                <Column
                  title="标题"
                  width={'50%'}
                  dataIndex="title"
                  key="title"
                  render={(title: any) => <span dangerouslySetInnerHTML={{ __html: title }}></span>}
                />
                <Column
                  title="作者"
                  width={80}
                  dataIndex="loginName"
                  key="loginName"
                  render={(loginName: any) => loginName}
                />
                <Column
                  title="分类"
                  width={80}
                  dataIndex="groupNames"
                  key="groupNames"
                  render={(groupNames: any) => groupNames}
                />
                <Column
                  title="题型"
                  width={80}
                  dataIndex="subjectType"
                  key="subjectType"
                  render={(subjectType: any) => {
                    let subjectTypeStr: any = ''
                    switch (subjectType) {
                      case QuesType.SINGLE_SEL:
                        subjectTypeStr = '单选题'
                        break
                      case QuesType.MULTI_SEL:
                        subjectTypeStr = '多选题'
                        break
                      case QuesType.TRUE_FLS:
                        subjectTypeStr = '判断题'
                        break
                    }
                    return subjectTypeStr
                  }}
                />
                <Column
                  title="分数"
                  width={80}
                  dataIndex="solScore"
                  key="solScore"
                  render={(solScore: any) => solScore || 0}
                />
                {hasPermits(PERM.ques_func_mod) && (
                  <Column
                    title="操作"
                    width={80}
                    dataIndex="subjectId"
                    key="subjectId"
                    render={(subjectId: any, row: any) => (
                      <QuesAdd
                        icon="edit"
                        buttonTitle="编辑题目"
                        subjectId={row.subjectId}
                        handleOk={this.handleOk}
                      />
                    )}
                  />
                )}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<IQuesProps>()(Ques)
